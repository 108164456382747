import Slider from "rc-slider";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { BiSolidVolumeFull, BiSolidVolumeMute } from "react-icons/bi";
import { BsFillPlayFill } from "react-icons/bs";
import { TbArrowsDiagonal, TbArrowsDiagonalMinimize2 } from "react-icons/tb";
import ReactLoading from "react-loading";
import ReactPlayer from "react-player/lazy";

import "./PlayerPreview.scss";

class PlayerPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTimePreview: 0,
      playPreview: false,
      pipPreview: false,
      mutePreview: true,
      reload: true,
    }
    this.refPreview = this.refPreview.bind(this);
    this.changePreviewTime = this.changePreviewTime.bind(this);
  }

  refPreview(player) {
    this.preview = player;
  }

  changePreviewTime(time) {
    const { sliceSelected } = this.props;

    if (time > sliceSelected.end) {
      this.setState({ playPreview: false, reload: true });
    } else {
      this.setState({ currentTimePreview: time });
      if (!this.preview) setTimeout(() => this.changePreviewTime(time), 1000);
      else this.preview.seekTo(time, "seconds");
    }
  }

  render() {
    const { url, slice, sliceSelected } = this.props;
    const { currentTimePreview, playPreview, pipPreview, mutePreview, reload } = this.state;
    const prewiewLoaded = this.preview?.player.prevPlayed === undefined ? false : true;

    return (
      sliceSelected.id === slice.id && !reload
        ? <div className="selected">
          <Col style={{ opacity: prewiewLoaded ? 1 : 0.5 }}>
            <Row onClick={() => { if (this.preview?.player.prevPlayed <= slice.end) this.setState({ playPreview: !playPreview }) }}>
              <ReactPlayer
                id={`preview-${slice.id}`}
                ref={this.refPreview}
                url={url}
                controls={false}
                muted={mutePreview}
                playing={prewiewLoaded && playPreview}
                pip={pipPreview}
                width="150px"
                height="auto"
                onDisablePIP={() => this.setState({ pipPreview: false })}
                onProgress={(e) => this.changePreviewTime(e.playedSeconds)}
              />
            </Row>
            {
              !prewiewLoaded &&
              <Col className='preview-spinner__container'>
                <ReactLoading className="preview-spinner" type={"spin"} color={"#a836ff"} />
              </Col>
            }
            <Row className="controls-row">
              <Col className='player-progress-container'>
                <Slider
                  className="player-progress"
                  min={~~slice.start}
                  max={~~slice.end}
                  value={currentTimePreview}
                  onChange={this.changePreviewTime}
                  railStyle={{ height: "4px", "backgroundColor": "#e9e9e963" }}
                />
              </Col>
              <Col className="options">
                {
                  mutePreview
                    ? <BiSolidVolumeMute onClick={() => this.setState({ mutePreview: false })} />
                    : <BiSolidVolumeFull onClick={() => this.setState({ mutePreview: true })} />
                }
                {
                  pipPreview
                    ? <TbArrowsDiagonalMinimize2 onClick={() => this.setState({ pipPreview: false })} />
                    : <TbArrowsDiagonal onClick={() => this.setState({ pipPreview: true })} />
                }
              </Col>
            </Row>
          </Col>
        </div>
        : <div
          className="not-selected"
          onClick={() => {
            this.setState({ pipPreview: false, mutePreview: true, playPreview: true, reload: false, currentTimePreview: slice.start });
            this.changePreviewTime(slice.start);
          }}>
          <BsFillPlayFill />
        </div>
    )
  }
}

export default PlayerPreview;