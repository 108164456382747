export const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      throw response;
    }
};

export const parseSecondsToHMS = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const resto = seconds % 3600;
    const m = Math.floor(resto / 60);
    const s = seconds % 60;
  
    let result = '';
    if (h > 0) result += `${parseDateToString(h)}:`
    if (h > 0 || m > 0 || s >= 0) result += `${parseDateToString(m)}:`
    result += `${parseDateToString(s)}`;
    return result;
}

export const parseDateToString = (number) => {
  let n = number;
  if (n < 10) n = `0${n}`;
  return n;
};

