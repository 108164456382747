import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';

export const createAlert = (title, message, buttons) => {
  confirmAlert({
    title,
    message,
    buttons,
  });
};

export const createAlertWithCustomUI = (customUI) => {
  confirmAlert({
    customUI
  })
}