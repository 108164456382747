
import { config } from './config';
import { getTokenCookie } from './cookies';
import { checkStatus } from './util';

const URL_BASE_MAM = config.urlBaseMamApi;
const URL_BASE_CLIENT_API = config.urlBaseClientApi;
const headers = {
  'Content-Type': 'application/json',
  'X-Access-Token': getTokenCookie()
};

async function Get(url, options, flag = true) {
  const auxOptions = options;
  if ('body' in auxOptions && flag) auxOptions.body = JSON.stringify(auxOptions.body);
  try {
    let response = await fetch(url, auxOptions);
    response = checkStatus(response);
    return await response.json();
  }
  catch (err) {
    throw err;
  }
}

export function editClip(videoInfo, platformId) {
  const url = `${URL_BASE_MAM}/coconut/cut`;
  const options = {
    method: 'POST',
    headers,
    body: {
      videoInfo,
      platformId,
    }
  }
  return Get(url, options);
}

export function getMediaByFilters(codeToken, icaaId) {
  const url = `${URL_BASE_MAM}/media/filter`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Access-Token': codeToken
    },
    body: {
      customMetadata_ICAA_id: icaaId,
    }
  }
  return Get(url, options);
}

export function getSlices(tyrisToken, icaId) {
  const url = `${URL_BASE_CLIENT_API}/preorder/${icaId}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tyrisToken}`
    },
  }
  return Get(url, options);
}

export function insertSlices(tyrisToken, icaId, slices) {
  const url = `${URL_BASE_CLIENT_API}/preorder/${icaId}`;
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tyrisToken}`
    },
    body: {
      itemId: icaId,
      id: icaId,
      clips: slices

    }
  }
  return Get(url, options);
}