export const environment = {
  hostname: 'cdnapi.codev8.net',
  token: '64yYVONXXbRHq68LCpbVXUzfnutVoMTd',
  media: {
    'id':'f1f333bb-7f81-4c90-8127-2f899a33d47a',
    'type':'mp4',
    'title':'Semana Santa Tobarra. Imágenes de Villena. Semana Santa Hellín',
    'description':'Imágenes de la Semana Santa en Tobarra y Hellín, y de la fiestas de Villena.',
    'duration': 984,
    'm3u8': '//storagecdnvlc.codev8.net/ondemand/e8700c13-b317-4bcf-9550-610e2ddb8e8f/f1f333bb-7f81-4c90-8127-2f899a33d47a/hls/master.m3u8',
    'bitrates':[
      {
        'bitrate':0,
        'fileSize':0,
        'filename':'',
        'url':''
      }
    ],
    'folder':{} //entire object
  }
}
